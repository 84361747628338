@font-face {
    font-family: "AmazoneW01-Regular";
    src: url('./css.module/fonts/AmazoneW01-Regular Shorted(Lanny mode).woff2');
    font-display: swap
}

@font-face {
    font-family: 'boyarskycregular';
    src: url('./css.module/fonts/BoyarskyC-Shorted(Feya).woff2') format('woff2');
    font-display: swap
}

@font-face {
    font-family: 'Material Icons';
    font-weight: 400;
    src: url('./css.module/fonts/MaterialIcons-Regular-Short.woff2') format('woff2');
    font-display: swap
}

.material-icons {
    font-family: 'Material Icons';
    font-style: normal;
    text-transform: none;
}

@import "~materialize-css/dist/css/materialize.min.css"
