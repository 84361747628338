.container {
    margin: 0 auto;
    max-width: 1280px;
    width: 90%;
}

@media only screen and (min-width: 150px) {
    .container {
        width: 100%;
    }
}

@media only screen and (min-width: 601px) {
    .container {
        width: 100%;
    }
}
@media only screen and (min-width: 719px) {
    .container {
        width: 85%;
    }
}

@media only screen and (min-width: 993px) {
    .container {
        width: 70%;
    }
}
